import Papa from "papaparse"
import React, {useState, useEffect} from "react";

export function parseSheet(url) {
    Papa.parse(url, {
          download: true,
          header: true,
          complete: function(results) {
            var data = results.data
          }
        })
}

export async function getCols(url) {
    Papa.parse(url, {
          download: true,
          header: false,
          complete: function(results) {
            var data = results.data
            return data[0];
          }
        })
}

function checkSubmit(alias, url) {
    if (alias === "" || url === "") {
        alert("Please fill out all fields.");
        return false;
    }

    // check if url is a valid google sheets url
    if (!
        (url.includes("https://docs.google.com/spreadsheets/d/") && 
        url.includes("output=csv") && 
        url.includes("pub"))
    ) {
        alert("Please enter a valid Google Sheets URL.");
        return false ;
    }

    const blacklist = [
        "about",
        "account",
        "profile",
        "admin",
        "pricing",
        "dashboard",
        "login",
        "logout",
        "register",
        "settings",
        "support",
        "terms",
        "privacy",
        "contact",
        "api",
        "blog",
        "help",
        "jobs",
        "press",
        "features",
        "tos",
        "docs",
        "doc",
        "setup",
        "signup",
        "signin",
        "get-started",
        "download",
        "downloads",
        "apps",
        "app",
        "ios",
        "android",
        "chrome",
        "firefox",
        "extension",
        "extensions"
    ];

    if (
        alias.includes(" ") ||
        alias.includes("/") ||
        alias.includes("\\") ||
        alias.includes("?") ||
        alias.includes("#") ||
        alias.includes("%") ||
        alias.includes("*") ||
        alias.includes(":") ||
        alias.includes("|") ||
        alias.includes('"') ||
        alias.includes("<") ||
        alias.includes(">") ||
        alias.length > 15 ||
        alias.length < 3
    ) {
        alert(
            "Please enter a valid alias. (3-15 characters, no spaces or special characters)"
        );
        return false;
    } else if (blacklist.includes(alias)) {
        alert("This alias is reserved. Please choose another.");
        return false;
    }
    return true;
}

export default checkSubmit;