import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import SignUp from "./pages/SignUp";
import Pricing from "./pages/Pricing";
import LogIn from "./pages/LogIn";
import Profile from "./pages/Profile";
import Dashboard from "./pages/Dashboard";
import Feed from "./pages/Feed";
import React from 'react';
import { createRoot } from 'react-dom/client';

export default function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<NoPage />} />
            <Route path="pricing" element={<Pricing />} />
            <Route path="login" element={<LogIn />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="profile" element={<Profile />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="*" element={<Feed />}  />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);