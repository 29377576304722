import React, {useState, useEffect} from "react";
import  { Navigate, useLocation, useNavigate } from 'react-router-dom'
import Papa from "papaparse"
import '../styling/Dashboard.css';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import app, {db} from "../utils/firebase";
import checkSubmit, {parseSheet, getCols} from "../scripts/functions.js";
import { onValue, ref, set, get, query, equalTo, orderByChild, once, orderByValue, off, update } from "firebase/database";
import {FaLink, FaEdit, FaQuestionCircle} from "react-icons/fa";

const Dashboard = () => {
    const [alias, setAlias] = useState("");
    const [url, setURL] = useState("");
    const [feeds, setFeeds] = useState([]);
    const [show, setShow] = useState("");
    const [user, setUser] = useState(0);
    const [loading, setLoading] = useState(true);

    const [cols, setCols] = useState([]);

    const [feedTitle, setFeedTitle] = useState('');
    const [feedColor, setFeedColor] = useState("#000000");
    const [feedTheme, setFeedTheme] = useState("light");
    const [headlineCol, setHeadlineCol] = useState("");
    const [descCol, setDescCol] = useState("");
    const [footerCol, setFooterCol] = useState("");
    const [filters, setFilters] = useState([]);
    const [newFeed, setNewFeed] = useState(true);

    const [password, setPassword] = useState(false);
    const [passwordText, setPasswordText] = useState("");

    const auth = getAuth(app);

    const { state } = useLocation();

    const { startUrl } = state || { startUrl: "" };

    useEffect(() => {

        const params = new URLSearchParams(window.location.search);
        const s = params.get('s');
        if (s === "create") {
            setShow("create");
        } else if (s === "edit") {
            setShow("edit");
        } else {
            setShow("create");
        }
        
        setURL(startUrl);
        
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user.uid);
            } else {
            }
        });
  
        if (user) {
            const userRef = ref(db, "users/" + user + "/feeds");
            const userFeeds = [];
            onValue(userRef, (snapshot) => {
                if (snapshot.val() === null) {
                    setFeeds([]);
                    setLoading(false);
                    return;
                }
                const promises = snapshot.val().map((feedAlias) => {
                    const feedRef = ref(db, "feeds/" + feedAlias + "/");
                    return get(feedRef).then((childSnapshot) => {
                        const feed = childSnapshot.val();
                        if (feed != null) {
                            userFeeds.push(feed);
                        }
                    });
                });
        
                Promise.all(promises).then(() => {
                    setFeeds(userFeeds); 
                    setLoading(false);
                });
            });
        }
        
    }, [setFeeds, user]);
    
    const Push = () => {
        if (checkSubmit(alias, url) === false) {
            return;
        }

        const dbRef = ref(db, "feeds/" + alias + "/");
        get(dbRef).then((snapshot) => {
            if (snapshot.exists()) {
                alert("This alias already exists. Please choose another.");
            } else {
                parseSheet(url);
                let fetchCols = new Promise((resolve, reject) => {
                    try {
                        Papa.parse(url, {
                            download: true,
                            header: false,
                            complete: function(results) {
                            var data = results.data
                            resolve(data[0]);
                            }
                        })
                    } catch (any) {
                        reject("Error");
                    }
                });
                fetchCols.then( 
                    (value) => {
                        setCols(value);
                        setHeadlineCol(value[0])
                        setDescCol(value[0])
                        setFooterCol(value[0])
                        setFilters(new Array(value.length).fill(false));

                        setShow("setup");
                    },
                    (error) => alert("Error")
                )    
            }
        });

    }

    const submitFeed = (isNew) => {
        if (password && passwordText === "") {
            alert("Please enter a feed password or disable password-protection.");
            return;
        }
        const dbRef = ref(db, "feeds/" + alias + "/");
        get(dbRef).then((snapshot) => {
            const data = {
                url: url,
                alias: alias,
                title: feedTitle === '' ? alias : feedTitle,
                color: feedColor,
                theme: feedTheme,
                head: headlineCol,
                desc: descCol,
                footer: footerCol,
                cols: cols,
                user: user,
                filters: filters,
                password: {enabled: password, text: passwordText}
            };
            if (isNew) {
                set(dbRef, data);
            } else {
                update(dbRef, data);
                alert("Feed updated successfully!")
            }
        })
        if (isNew) {
            get(ref(db, "users/" + user + "/feeds/")).then((snapshot) => {
                const userFeeds = snapshot.val();
                if (userFeeds === null || userFeeds === undefined || userFeeds === ""  || userFeeds == []) {
                    set(ref(db, "users/" + user + "/feeds/"), [alias]);
                } else {
                    if (!userFeeds.includes(alias)) {
                        userFeeds.push(alias);
                        set(ref(db, "users/" + user + "/feeds/"), userFeeds);
                    }
                }
            });
            alert("Feed created successfully!");
        }   

    }

    const editFeed = (feedAlias) => {
        const dbRef = ref(db, "feeds/" + feedAlias + "/");
        get(dbRef).then((snapshot) => {
            const feed = snapshot.val();
            setCols(feed.cols);
            setAlias(feed.alias)
            setURL(feed.url)
            setUser(feed.user)
            setNewFeed(false);

            setFeedTitle(feed.title)
            setFeedColor(feed.color)
            setFeedTheme(feed.theme)
            setHeadlineCol(feed.head)
            setDescCol(feed.desc)
            setFooterCol(feed.footer)
            setFilters(feed.filters)

            setPassword(feed.password.enabled);
            setPasswordText(feed.password.text);

            setShow("setup");
        })
    }

    const handleCheck = (pos) => {
        const newState = filters.map((filter, index) => 
            index ===  pos ? !filter : filter
        );

        setFilters(newState);
    }

    const passwordProtect = () => {
        if (password) {
            setPassword(false);
        } else {
            setPassword(true);
        }
    }

  return (
    <>
        {user !== 0 && !loading && (
            <>

                
            <div className="dash-nav">
                <button onClick={() => setShow("create")}>New Feed</button>
                <button onClick={() => setShow("edit")}>My Feeds</button>
            </div>

            <div className="dashboard">
                
                {show === "create" && (
                    <div className="create-feed">
                        <h2>Create a new Feed</h2>
                        <form>
                            <label htmlFor="feedName">Feed Alias:</label>
                            <input type="text" id="feedName" name="feedName" onChange={(e) => setAlias(e.target.value)} value={alias} required />
                            <label htmlFor="feedURL" className="feedUrlLabel">
                                <FaQuestionCircle className="help-icon" onClick={
                                    () => alert("Please enter the URL of your Google Sheet. The URL should be in the format of https://docs.google.com/spreadsheets/d/your-sheet-id/export?format=csv. Remember to publish your sheet (File -> Share -> Publish to web -> CSV) to the web in order to get the URL.")
                                } />
                                <label>Feed URL:</label>
                            </label>
                            <input type="url" id="feedURL" name="feedURL" onChange={(e) => setURL(e.target.value)} value={url} required />
                            <input type="submit" value="Create" onClick={(e) => {
                                e.preventDefault();
                                Push();
                            }} />
                        </form>
                    </div>
                )}

                {show === "edit" && (
                    <div className="edit-feed">
                        <h2>Your Feeds</h2>
                        <div className="feed-list">
                            <ul>
                                {feeds !== null && feeds !== undefined && (
                                    feeds.map((feed, index) => (
                                        <li key={index}>
                                            <span>
                                                {feed.alias}
                                                 <button onClick={() => editFeed(feed.alias)}>
                                                    <FaEdit />
                                                 </button>
                                                <a href={"/feed/" + feed.alias} target="_blank">
                                                    <FaLink />
                                                </a>
                                            </span>
                                        </li>
                                    ))
                                )}   
                            </ul>
                        </div>
                    </div>
                )}

                {show === "setup" && (
                <>

                    <div className="feed-info">
                        <h2>Feed Setup</h2>

                        <div className="feed-form">
                            <span>Feed Title</span>
                            <input type="text" value={feedTitle} onChange={ev => setFeedTitle(ev.target.value)} placeholder={alias} /> <br />

                            <span>Feed Color</span>
                            <input type="color" value={feedColor} onChange={ev => setFeedColor(ev.target.value)} placeholder="Feed Color" /> <br />

                            <span>Feed Theme</span>
                            <select className="theme-select" value={feedTheme} onChange={ev => setFeedTheme(ev.target.value)}>
                                <option value="light">Light</option>
                                <option value="dark">Dark</option>
                            </select> <br />

                            <div className="feed-cols">

                                <div className="col-input">
                                    <span>Feed Headline Column</span>
                                    <select value={headlineCol} onChange={ev => setHeadlineCol(ev.target.value)}>
                                        {(cols.length !== 0 && cols !== undefined) && (
                                            cols.map((col, index) => (
                                            <option key={index}>
                                                {col}
                                            </option>
                                        ))
                                        )
                                    }
                                    </select>
                                </div>

                                <div className="col-input">
                                    <span>Feed Description Column</span>
                                    <select value={descCol} onChange={ev => setDescCol(ev.target.value)}>
                                        {(cols.length !== 0 && cols !== undefined) && (
                                            cols.map((col, index) => (
                                            <option key={index}>
                                                {col}
                                            </option>
                                        ))
                                        )
                                    }
                                    </select> 
                                </div>

                                <div className="col-input">
                                    <span>Feed Footer Column</span>
                                    <select value={footerCol} onChange={ev => setFooterCol(ev.target.value)}>
                                        {(cols.length !== 0 && cols !== undefined) && (
                                            cols.map((col, index) => (
                                            <option key={index}>
                                                {col}
                                            </option>
                                        ))
                                        )
                                    }
                                    </select>
                                </div>
                            
                            </div> <br />
                            
                            <span>Feed Filters</span>
                            <div className="filter-checklist">
                                {(cols.length !== 0 && cols !== undefined) && (
                                    cols.map((col, index) => (
                                        <div key={index}>
                                            <input type="checkbox" id={col} checked={filters[index]} onChange={() => handleCheck(index)} />
                                            <label htmlFor={col}>{col}</label>
                                        </div>
                                    ))
                                )
                                }
                            </div> <br />

                            <span>Password Protection</span>
                            <div className="password-protection">
                                <input type="checkbox" id="password" checked={password} onChange={() => passwordProtect()} />
                                <label htmlFor="password">Require a password to access this feed</label> <br />
                            </div> 
                            {password && (
                                <input type="text" id="password" name="password" placeholder="Enter Feed Password" value={passwordText} onChange={(e) => {setPasswordText(e.target.value)}} />
                            )}
                            <br />

                            <button className="feed-submit" onClick={() => submitFeed(newFeed)}>Submit</button>
                        </div>

                    </div>

                </>
                )}

            </div>

            <br />
            <br /> 
        </>
    )}
    {(setShow == "feed-created" || setShow == "feed-edited") && (
        <div className="feed-created">
            <h2>Feed Created!</h2>
            <p>Share your feed with this link:</p>
            <a href={"/" + alias} target="_blank">{window.location.origin}/{alias}</a>
        </div>
    
    )}
    {user == 0 && !loading && (
        <Navigate to="/login" />
    )}
    {loading && (
        <div className="loader"></div>
    )}
    </>
    )
};

export default Dashboard;