import React, {useState} from "react";
import { Navigate } from "react-router-dom";
import { FaGoogle } from "react-icons/fa";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import app from "../utils/firebase"
import '../styling/SignUp.css';

const auth = getAuth(app);

const SignUp = () => {
  const [em, setEm] = useState("");
  const [pass, setPass] = useState("");
  const [alrAuth, setAlrAuth] = useState(false);

  const auth = getAuth(app);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setAlrAuth(true);
      const uid = user.uid;
      // ...
    } else {
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    createUserWithEmailAndPassword(auth, em, pass)
      .then((userCredential) => {
        // Signed up 
        const user = userCredential.user;
        setAlrAuth(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
    }
  return (  
    <>
    {!alrAuth && (
      <div id="signup">
          <p>Create a Sheet2Feed Account</p>
          <form onSubmit={handleSubmit}>
            <input type="text" placeholder="Email" id="em" value={em} onChange={ev => setEm(ev.target.value)} />
            <br /><br />
            <input type="password" placeholder="Password" id="pass" value={pass} onChange={ev => setPass(ev.target.value)} />
            <br /><br />
            <input type="submit" />
            <br /><br />
            <span className="or">OR</span>
            <br /><br />
            <button class="login-google" onClick={() => {
                const provider = new GoogleAuthProvider();
                signInWithRedirect(auth, provider);
              }}>
                <FaGoogle color="black" size="25"/>
                <span style={{marginLeft: 10}}>
                  Sign up with Google
                </span>
              </button>
          </form>
      </div>
    )}
    {alrAuth && (
      <Navigate to="/profile" />
    )}
    </>
  )
};

export default SignUp;