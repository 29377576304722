import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyCHqm6mCsxPnpn03n98-mxFayCuMWoZJ4k",
    authDomain: "sheet2feed-967bf.firebaseapp.com",
    databaseURL: "https://sheet2feed-967bf-default-rtdb.firebaseio.com/",
    projectId: "sheet2feed-967bf",
    storageBucket: "sheet2feed-967bf.appspot.com",
    messagingSenderId: "69489818760",
    appId: "1:69489818760:web:7950f47fb108a24afb333d",
    measurementId: "G-E220QNYT49"
  };

const app = initializeApp(firebaseConfig);
const db = getDatabase();
 
export default app;
export {db};