import React, {useState} from "react";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { Navigate, useNavigate } from "react-router-dom";
import app from "../utils/firebase"
import '../styling/SignUp.css';
import { FaGoogle } from "react-icons/fa";

const auth = getAuth(app);

const LogIn = () => {
  const [em, setEm] = useState("");
  const [pass, setPass] = useState("");
  const [alrAuth, setAlrAuth] = useState(false);

  const auth  = getAuth(app);   

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setAlrAuth(true);
    } 
}); 

  const handleSubmit = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, em, pass)
        .then((userCredential) => {
        const user = userCredential.user;
        setAlrAuth(true);
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode);
    console.log(errorMessage);
  });
    }

  return (  
    <>
        {!alrAuth && (
        <div id="signup">
            <p>Log into your Sheet2Feed Account</p>
            <form onSubmit={handleSubmit}>
              <input type="text" placeholder="Email" id="em" value={em} onChange={ev => setEm(ev.target.value)} />
              <br /><br />
              <input type="password" placeholder="Password" id="pass" value={pass} onChange={ev => setPass(ev.target.value)} />
              <br /><br />
              <input type="submit" />
              <br /><br />
              <span>OR</span>
              <br /><br />
              <button class="login-google" onClick={() => {
                const provider = new GoogleAuthProvider();
                signInWithRedirect(auth, provider);
              }}>
                <FaGoogle color="black" size="25"/>
                <span style={{marginLeft: 10}}>
                  Log in with Google
                </span>
              </button>
            </form>
        </div>
        )}
        {alrAuth && (
            <Navigate to="/profile" />
        )}
    </>
  )
};

export default LogIn;