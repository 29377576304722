import { Outlet, Link } from "react-router-dom";
import '../styling/Nav.css';
import React, {useEffect, useState} from "react";

import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import app from "../utils/firebase";

import { FaBars} from 'react-icons/fa';

const auth = getAuth(app);

const Layout = () => {
  const [isAuthenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticated(true);
        const uid = user.uid;
      } else {
        setAuthenticated(false);
      }
    });
  }, []);

  const showNav = () => {
    try {
      const nav = document.querySelector('.nav');
      nav.classList.toggle('nav-open');
      nav.classList.toggle('nav');
    } catch (error) {
      const nav = document.querySelector('.nav-open');
      nav.classList.toggle('nav');
      nav.classList.toggle('nav-open');
    }
  }
  
  return (
    <>
      <FaBars className="nav-toggle" size={30} onClick={showNav} />
      <nav className="nav">
        <ul className="nav-list-main">
          <li className="nav-item">
            <Link to="/" className="link">Sheet2Feed</Link>
          </li>
        </ul>
        <ul className="nav-list">
          <li className="nav-item">
            <a href={window.location.origin + "#about"} className="link">About</a>
          </li>
          <li className="nav-item">
            <Link to="/pricing" className="link">Pricing</Link>
          </li>
          {isAuthenticated && (
            <>
              <li className="nav-item">
                <Link to="/profile" className="link">Profile</Link>
              </li>
              <li className="nav-item">
                <Link to="/dashboard" className="create">Dashboard</Link>
              </li>
            </>
          )}
          {!isAuthenticated && (
            <>
              <li className="nav-item">
                <Link to="/login" className="link">Log In</Link>
              </li>
              <li className="nav-item">
                <Link to="/signup" className="create">Sign up</Link>
              </li>
            </>
          )}
        </ul>
      </nav>

      <Outlet />
    </>
    )
};

export default Layout;
