import React, {useState} from "react";
import { Navigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import app from "../utils/firebase"
import '../styling/SignUp.css';

const Profile = () => {
    

    return (
        <>
        <div>
            <h1>Sheet2Feed Pricing</h1>
            <h2>Currently Free For All Users</h2>
        </div>
        </>
    );
    };

export default Profile;