import React, {useState} from "react";
import { Navigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import app from "../utils/firebase"
import '../styling/SignUp.css';
import '../styling/Profile.css';

const Profile = () => {
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(true);

    const auth = getAuth(app);

    onAuthStateChanged(auth, (user) => {
        if (user) {
            setAuthenticated(true);
            setUser(user);
        } else {
            setAuthenticated(false);
            setLoading(false);
        }
    });
    
    const logout = () => {
        auth.signOut().then(() => {
            setAuthenticated(false);
        }).catch((error) => {
           console.log(error)
        });
    }

    return (
        <>
        {isAuthenticated && (
        <div>
            <h1>Your Sheet2Feed Profile</h1>
            <img src={user.photoURL} alt="Profile" className="profile-pic" />
            <h2>{user.name}</h2>
            <p>{user.email}</p>
            <button onClick={logout} className="login-google">
                Log Out
            </button>
        </div>
        )}
        {!isAuthenticated && !loading && (
            <Navigate to="/login" />
        )}
        </>
    );
    };

export default Profile;